import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import './StackSlider.scss';

function StackSlider({ data, imageClass, settings }) {
  return (
    <div className="stack-slider-slick ml-[5%] md:ml-0 md:mr-[-19px]">
      <Slider {...settings}>
        {data.map((d) => (
          <div className="w-full md:w-1/3 px-3 md:p-[19px]" key={d.id}>
            <img
              {...d.image}
              alt=""
              className={`w-full rounded ${imageClass}`}
            />
            <div className="text-md md:text-3md font-semibold md:text-center pt-4 md:pt-5">
              {d.text}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

StackSlider.propTypes = {
  imageClass: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
      image: PropTypes.shape({}),
    }),
  ),
  settings: PropTypes.shape({}),
};

StackSlider.defaultProps = {
  data: [],
  imageClass: '',
  settings: {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.3,
          infinite: false,
          arrows: true,
          dots: true,
        },
      },
    ],
  },
};

export default StackSlider;
