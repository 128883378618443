import React from 'react';
import TabPanel from '../components/Tabs/TabPanel';

export const terminalLine = '!text-tiny md:!text-2sm';
export const checkList = [
  { id: 0, listItem: 'Bank account' },
  { id: 1, listItem: 'UPI ID' },
  { id: 2, listItem: 'IFSC ' },
];
export const tryCheckList = [
  { id: 0, listItem: <>Validate the accuracy of bank account&nbsp;details</> },
  { id: 1, listItem: <>Match the name with account holder&nbsp;name</> },
  { id: 2, listItem: <>Verify if the IFSC is&nbsp;correct</> },
];
export const useCases = [
  {
    id: 0,
    text: 'KYC Verification',
    image: {
      src: '/img/bav/case-1.png',
      width: '796',
      height: '536',
    },
  },
  {
    id: 1,
    text: 'Zero transfer reversals',
    image: {
      src: '/img/bav/case-2.png',
      width: '788',
      height: '536',
    },
  },
  {
    id: 2,
    text: 'Accurate payouts everytime',
    image: {
      src: '/img/bav/case-3.png',
      width: '788',
      height: '536',
    },
  },
];
const headingClass = 'mb-2 md:mb-[12px]';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName = 'w-full md:pr-[18px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
export const verificationSuiteData = [
  {
    key: 0,
    heading: 'Bank Account Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-1.svg',
            width: '610',
            height: '430',
          }}
          heading="Bank account Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Verify if a bank account exists, match name provided by
                beneficiary with name at bank and minimise the failed or
                transfer reversals. Know the accurate reason for verification
                failure.
              </div>
            </>
          }
          footer={null}
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'UPI ID Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-2.svg',
            width: '610',
            height: '423',
          }}
          heading="UPI ID Verification "
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Enter UPI ID and know if it exists. You will receive a customer
                name at the bank in the response for valid UPIs.
              </div>
            </>
          }
          footer={
            <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-base text-white rounded relative mt-4 border border-cf-stroke max-w-[460px] ml-[4px] md:ml-0">
              <div className="ribbon-purple">Link with Payouts</div>
              <span className="block mt-1 text-2.5sm leading-[24px]">
                Activate Verification Suite and use it with Cashfree Payouts to
                ensure accurate payouts every time.
              </span>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: (
      <div>
        IFSC Verification
        {/* <span className="bg-cf-faded-black text-white px-[10px] py-[3px] text-[11px] rounded-full inline-block ml-2 leading-3 font-normal free-badge">
          FREE
        </span> */}
      </div>
    ),

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-3.svg',
            width: '610',
            height: '423',
          }}
          heading={
            <div>
              IFSC Verification
              {/* <span className="bg-cf-primary text-white px-[12px] py-[3px] text-2sm rounded-full inline-block ml-2 font-semibold free-badge">
                FREE
              </span> */}
            </div>
          }
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                The most exhaustive list of IFSC codes updated weekly. Verify
                whether the IFSC is valid, the bank name and bank branch that it
                belongs to.
              </div>
            </>
          }
          footer={
            <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-base text-white rounded relative mt-4 border border-cf-stroke max-w-[460px] ml-[4px] md:ml-0">
              <div className="ribbon-purple">Bonus</div>
              <span className="block mt-1 text-2.5sm leading-[24px]">
                Know the transfer modes supported - <b>NEFT, IMPS, RTGS</b> and
                choose payout method accordingly.
              </span>
            </div>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'PAN Verification',

    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-4.svg',
            width: '610',
            height: '430',
          }}
          heading="PAN Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Verify if the PAN is valid, identify the registered name of the
                card holder along with the nature of the card holder. Do a
                single verification or a bulk verification of upto 10,000 PAN
                numbers instantly via API.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/PAN-verification/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 4,
    heading: 'Aadhaar Verification',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-5.png',
            width: '610',
            height: '430',
          }}
          heading="Aadhaar Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Validate user’s 12-digit Aadhaar number along with the OTP sent
                to the registered mobile number of users and get details like
                user’s name, date of birth, father’s name, user’s image,
                address, gender, state, mobile number and email ID of their
                mobile number, in&nbsp;seconds.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/aadhaar-verification/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 5,
    heading: 'GSTIN Verification',
    content: (
      <div className="md:mt-[60px]">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/bav/suite-6.svg',
            width: '610',
            height: '430',
          }}
          heading="GSTIN Verification"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Validate 15 digit GSTIN number and get details of valid GSTIN
                like GSTIN status, registered business name, nature of business,
                business address and taxpayer type like regular, composit
                and&nbsp;exempted.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/gst-verification/">
              Learn More <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];
export const otherFeatureData = [
  {
    id: 0,
    icon: '/img/icons/payouts-green.svg',
    title: 'Payouts',
    text:
      'Make payouts to any bank account/UPI ID/card/AmazonPay/Paytm instantly even on a bank holiday.',
    url: 'https://www.cashfree.com/payouts/',
    maxWidth: 28,
  },
  {
    id: 1,
    icon: '/img/icons/cashgram.svg',
    title: 'Cashgram',
    text:
      "Payout links enable you to send money without the receiver’s bank account details. Verify the beneficiaries' account number to ensure money is credited to intended beneficiaries only.",
    url: 'https://www.cashfree.com/cashgram/',
    maxWidth: 32,
  },
  {
    id: 2,
    icon: '/img/icons/easy-split.svg',
    title: 'Easy Split',
    text:
      'Use Easy Split to seamlessly split commission and disburse payments to all partner vendors after every sale, and reconcile with ease. Run a marketplace with ease.',
    url: 'https://www.cashfree.com/easy-split/split-payment-gateway/',
    maxWidth: 36,
  },
];

export const pricingData = [
  { id: 0, text: 'Paperless and same day onboarding' },
  { id: 1, text: 'Easy to use Dashboard' },
  { id: 2, text: 'E-commerce Plugins' },
  { id: 3, text: 'Developer friendly integration kits' },
];
export const bavWho = [
  {
    id: 0,
    icon: '1.svg',
    heading: 'Marketplaces',
    description:
      'Verify user’s and vendors and ensure that the Payout amount is credited to intended beneficiaries only.',
  },
  {
    id: 1,
    icon: '2.svg',
    heading: 'Businesses and individuals for payment verification',
    description:
      'Verify the bank account details and match the name registered at the bank to avoid any transfer failures or reversals.',
  },
  {
    id: 2,
    icon: '3.svg',
    heading: 'Payments and fintech companies',
    description:
      'Ensure frictionless onboarding, Verify users details at the bank to avoid details mismatch or failed transactions.',
  },

  {
    id: 3,
    icon: '4.svg',
    heading: 'Insurance providers',
    description:
      'Authenticate individuals and users during onboarding or before disbursing the insurance amounts. ',
  },
  {
    id: 4,
    icon: '5.svg',
    heading: 'Wallet service providers',
    description:
      'Fastrack user onboarding and account activations with Instant users/businesses account verifications.',
  },
  {
    id: 5,
    icon: '6.svg',
    heading: 'Security and equity investment platforms',
    description:
      'Enable users to start invertmenting on your platform faster with Bank Account Verification. Integrate APIs that are highly customisable and easy to use.',
  },
  {
    id: 6,
    icon: '7.svg',
    heading: 'Lending Platforms',
    description:
      'Verify the bank details before disbursing the loan to ensure zero transfer reversals or failures due to incorrect account number.',
  },
  {
    id: 7,
    icon: '8.svg',
    heading: 'Recruitment and identification verification companies',
    description:
      'Verify the bank account details and match the name of employees for a faster onboarding process and minimize failed transfers.',
  },
];

export const faqData = {
  data: [
    {
      id: 0,
      q: 'How much time does verification take?',
      a: (
        <p>
          On Cashfree Payments you can verify a/c numbers instantly. As you
          submit the request, our API gives an instant response.
        </p>
      ),
    },
    {
      id: 1,
      q: 'For how many banks, can I do the verification?',
      a: (
        <>
          <p>
            The verification can be done for all the a/c of recognized Indian
            banks including all public banks, private banks, and 126+
            co-operative banks*.
          </p>
          <p>
            Note: Verification may not be possible for some co-operative bank
            a/cs.
          </p>
        </>
      ),
    },
    {
      id: 2,
      q:
        'How to verify a/c number on Cashfree Payments for multiple individuals?',
      a: (
        <div>
          <p>
            On Cashfree Payments, you can do verification for any number of
            Indian bank accounts via two modes:
          </p>
          <ul className="list-decimal">
            <li>
              Manual entry on Cashfree Payments Bank account verification
              dashboard. Here you will be required to enter only two details:
              Indian bank account number and IFSC. If you want to do bulk
              verification, you can access the bulk upload feature on the
              dashboard where you can upload a csv/xslx file with required
              details and get a quick response.
            </li>
            <li>
              Using API This is an integrated automated way to do single/bulk
              verification. The API can be integrated with existing company
              internal system or ERP and then request can be pushed directly
              using API.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 3,
      q:
        'Is there any limit on the total number of bank account verification requests per day?',
      a: (
        <p>
          No, there is no such limit. You can use the fetaure to verify any
          number of a/cs in a day 24*7.
        </p>
      ),
    },
    {
      id: 4,
      q: 'How can I use bank account verification API for KYC?',
      a: (
        <>
          <p>
            KYC or “Know Your Customer” is a process to be undertaken by
            businesses especially financial institutions to obtain information
            about the identity and address of the customers. One of the key
            document is bank account details as the same is regarded as valid
            proof for the identity of a person/entity.
          </p>
          <p>
            While onboarding vendors, delivery partners, or even employees, an
            organisation needs to do a bank account verification. Using Cashfree
            Payments API, this process can be automated and integrated with
            existing company internal system so that the bank verification
            process happens smoothly, without exposing the data to any third
            party. You can also verify PAN, Aadhaar and GSTIN of users or
            businesses with our Verification Suite.
          </p>
        </>
      ),
    },
    {
      id: 5,
      q: 'What are the other use cases of this feature?',
      a: (
        <>
          <p>
            The capability to verify a/c can be used in multiple business
            scenarios such as
          </p>
          <ul className="list-decimal">
            <li>
              Crucial part of verification process before providing loans of all
              kind (health,business,car etc)
            </li>
            <li>
              Employee background check : a recruitment agency can use the
              feature for candidate&apos;s background check
            </li>
            <li>
              Vendor onboarding: a marketplace splitting commission with
              different vendors can use the feature to verify the a/c details at
              the time of onboarding the vendor, this also helps in correct
              payments later on. The ability to integrate the API with existing
              ERP helps in reducing operational effort and saves cost.
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 6,
      q:
        'I am planning to opt for Cashfree Payouts for bulk disbursals. Do I need to go for verification API separately?',
      a: (
        <p>
          Yes, Payouts is different. Payouts user can integrate BAV to do the
          validation & will be charged separately.
        </p>
      ),
    },
    {
      id: 7,
      q:
        'I want to use Cashfree Payments marketplace settlement for paying vendors. Do I need to take verification feature separately?',
      a: (
        <p>
          Yes, if you are using Cashfree Payments marketplace settlement and
          want to use verification API as well, you need to opt for the feature
          seperately. Our experts will help you with integration and you will be
          able to use Cashfree Payments marketplace settlement and verification
          feature together.
        </p>
      ),
    },
    {
      id: 8,
      q: 'Why is bank account verification important?',
      a: (
        <p>
          Our Plug and Play integrated a/c number verification API helps you
          confirm bank account validity. By accessing updated data, the risk of
          identity fraud and incorrect/incomplete data is reduced. The result
          such as account holder’s name shown to you will be exactly as it
          appears in bank records.
        </p>
      ),
    },
  ],
  customer: [
    {
      id: 0,
      q: 'What is UPI ID Verification?',
      a: (
        <>
          <p>
            UPI ID (also called Virtual Payment Address or VPA) is a unique ID
            you need to create in order to send and accept money via UPI . Once
            a UPI address is created and mapped against your existing bank
            account, you can simply share your UPI address (eg. abc@okaxis) with
            the payer.
          </p>
          <p>
            As a business, the ability to verify a UPI ID comes handy. The
            different use cases can&nbsp;be
          </p>
          <ul className="list-disc">
            <li>
              Background verification at the time of onboarding a new
              user/vendor
            </li>
            <li>Before releasing payment to a UPI ID </li>
          </ul>
          <p>
            Cashfree Payments provides instant means to verify if the UPI ID is
            valid and shows name of the account holder.
          </p>
        </>
      ),
    },
    {
      id: 1,
      q: 'How much time does the verification take?',
      a: (
        <p>
          On Cashfree Payments you can verify any number of UPI IDs instantly.
          As you submit the request, our API gives an instant response.
        </p>
      ),
    },
    {
      id: 2,
      q:
        'How to verify a/c number on Cashfree Payments for multiple individuals?',
      a: (
        <>
          <p>
            On Cashfree Payments, you can do verification for any number of UPI
            IDs of individuals or entities via two modes:
          </p>
          <ul className="list-decimal">
            <li>
              Manual entry on Cashfree Payments UPI ID verification dashboard.{' '}
              <span className="block">
                Here you will be required to enter only one detail: UPI ID (eg
                abc@okaxis)
              </span>
            </li>
            <li>
              Using API
              <span className="block">
                This is an integrated automated way to do upi verification. The
                API can be integrated with existing company internal system or
                ERP and then request can be pushed directly using API.
              </span>
            </li>
          </ul>
        </>
      ),
    },
    {
      id: 3,
      q: 'Is there any limit on the total number of  UPI requests per day?',
      a: (
        <p>
          No, there is no such limit. You can use the feature to verify any
          number of UPI IDs in a day 24*7.
        </p>
      ),
    },
    {
      id: 4,
      q:
        'I am planning to opt for Cashfree Payouts for bulk disbursals on UPI ID. Do I need to go for verification API separately?',
      a: (
        <p>
          If you are a Payouts merchant, the Verification Suite of products such
          as Bank Account Verification (including UPI), Aadhaar Verification,
          GSTIN Verification, and PAN Verification will be automatically enabled
          for you
        </p>
      ),
    },
  ],
  tabs: [
    {
      id: 0,
      heading: 'Bank Account Verification',
      content: 'Using Cashfree for Bank Account Verification',
      icon: 'customer',
    },
    {
      id: 1,
      heading: 'UPI ID or VPA Verification',
      content: 'Using Cashfree for UPI/VPA verification',
      icon: 'at',
    },
  ],
};
export const otherProducts = [
  {
    id: 0,
    title: 'Payouts',
    body:
      'Make payouts to any bank account / UPI ID / card/ AmazonPay / Paytm instantly even on a bank holiday.',
    url: 'https://www.cashfree.com/payouts/',
    iconname: '/img/icons/payouts-green.svg',
  },
  {
    id: 1,
    title: 'Aadhaar Verification',
    body: (
      <>
        Instantly verify Aadhaar details of users with consent-based
        verification&nbsp;flow.
      </>
    ),
    url: 'https://www.cashfree.com/aadhaar-verification/',
    iconname: '/img/icons/aadhaar-verifcaiton.svg',
  },
  {
    id: 2,
    title: 'PAN verification',
    body: (
      <>
        Verify the validity of user&apos;s PAN along with cardholder details,
        at&nbsp;scale.
      </>
    ),
    url: 'https://www.cashfree.com/PAN-verification/',
    iconname: '/img/icons/pan-verifcation.svg',
  },
  {
    id: 3,
    title: 'GSTIN Verification',
    body: (
      <>Instantly verify GSTIN of vendors for quick KYC and&nbsp;onboarding.</>
    ),
    url: 'https://www.cashfree.com/gst-verification/',
    iconname: '/img/icons/gstin-verification.svg',
  },
];
export const customPricing = [
  {
    id: 0,
    item: 'Early access to new features',
  },
  {
    id: 1,
    item: 'Dedicated account manager',
  },
  {
    id: 2,
    item: 'Discounted pricing',
  },
  {
    id: 3,
    item: 'Support over WhatsApp in addition to other channels',
  },
];
export const nocode = [
  {
    id: 0,
    item:
      'Do single Bank Account Verification or do upto 10,000 accounts verification by using simple excel upload ',
  },
  {
    id: 1,
    item: `Just enter the beneficiary's account number/UPI ID/IFSC and get instant response`,
  },
];
export const instantBAV = [
  {
    id: 0,
    iconName: '/img/icons/verification.svg',
    title: <>Verification support for accounts of 600+&nbsp;banks</>,
    content: (
      <>
        <h2 className="inline font-body">Verify beneficiary accounts</h2>,
        account holder name, IFSC codes and UPI IDs for 600+ banks including
        public, private, rural and cooperative&nbsp;banks.
      </>
    ),
  },

  {
    id: 1,
    iconName: '/img/icons/bulk-bav.svg',
    title: <h2 className="inline font-body">Bulk Bank Account Verification</h2>,
    content: (
      <>
        Do a{' '}
        <h2 className="font-body inline">single Bank Account Verification</h2>{' '}
        or upto 10000 bank accounts in one go, via excel upload or&nbsp;APIs.
      </>
    ),
  },
  {
    id: 2,
    iconName: '/img/icons/name-verification.svg',
    title: <h2 className="inline font-body">Beneficiary name verification</h2>,
    content: (
      <>
        Verify the beneficiary name against the account holder name at
        the&nbsp;bank.
      </>
    ),
  },
  {
    id: 3,
    iconName: '/img/icons/approval-flow.svg',
    title: 'Inbuilt approval flow',
    content: (
      <>
        Assign maker and checker role to reduce human errors and
        higher&nbsp;accuracy.
      </>
    ),
  },
];
export const integratedBAV = [
  {
    id: 0,
    item: (
      <>
        Highly reliable and secure{' '}
        <span className="text-cf-white"> Bank Account Verification APIs </span>
      </>
    ),
  },
  {
    id: 1,
    item: (
      <>
        Use the official Cashfree Payments libraries for different programming
        languages to integrate with your product and automate account
        verification&nbsp;flow
      </>
    ),
  },
  {
    id: 2,
    item: (
      <>
        With webhooks, get notified on single or bulk verification status
        in&nbsp;real-time
      </>
    ),
  },
];
export const tabData = [
  {
    key: 0,
    heading: (
      <div className="flex items-center">
        <span className="inline-block w-[32px] h-[32px] mr-3">
          <img
            className="w-full h-full"
            src="/img/icons/green-board.svg"
            width="23"
            height="32"
            alt=""
          />
        </span>
        Know accurate reason for verification failure
      </div>
    ),
    content: (
      <>
        <p className="md:pl-[45px] md:pr-4 text-2sm md:text-md">
          Verification can fail due to reasons like invalid account number,
          incorrect IFSC, blocked, frozen or closed account. Know exactly why
          verification failed in real&nbsp;time.
        </p>
        <img
          className="md:hidden mt-6 mb-[16px] md:mb-0 mx-auto md:mx-0"
          src="/img/bav/panel-1.svg"
          alt=""
          width="454"
          height="428"
        />
      </>
    ),
  },
  {
    key: 1,
    heading: (
      <div className="flex items-center">
        <span className="inline-block w-[32px] h-[32px] mr-3">
          <img
            className="w-full h-full"
            src="/img/icons/purple-tick-circle.svg"
            width="31"
            height="23"
            alt=""
          />
        </span>
        10,000 account verifications in one go
      </div>
    ),
    content: (
      <>
        <p className="md:pl-[45px] md:pr-4 text-2sm md:text-md">
          Do 10,000 account verifications using powerful API or bulk upload via
          simple excel sheet. Verify without exposing sensitive financial data
          to any&nbsp;<span className="whitespace-nowrap">third-party</span>.
        </p>
        <img
          className="md:hidden mt-6 mb-[16px] md:mb-0 mx-auto md:mx-0"
          src="/img/bav/panel-2.svg"
          alt=""
          width="506"
          height="369"
        />
      </>
    ),
  },
  {
    key: 2,
    heading: (
      <div className="flex items-center">
        <span className="inline-block w-[32px] h-[32px] mr-3">
          <img
            className="w-full h-full"
            src="/img/icons/pink-approval.svg"
            width="32"
            height="25"
            alt=""
          />
        </span>
        Approval flow
      </div>
    ),
    content: (
      <>
        <p className="md:pl-[45px] md:pr-4 text-2sm md:text-md">
          Add users (Maker and Checker) to your account, and control the
          permissions. Every time a Maker uploads a file to do batch
          verification, the Checker(s) can review the files individually, and
          mark each record as valid or invalid and approve or reject
          the&nbsp;file.
        </p>
        <img
          className="md:hidden mt-6 mb-[16px] md:mb-0 mx-auto md:mx-0"
          src="/img/bav/panel-3.svg"
          alt=""
          width="534"
          height="424"
        />
      </>
    ),
  },
];
export const faqBAVData = tabData.map((t) => ({
  id: t.key,
  q: t.heading,
  a: t.content,
}));
export const heroLogoData = [
  {
    id: 0,
    src: 'zomato.png',
    width: '257',
    height: '55',
    className: 'max-w-[85px]',
    alt: 'zomato',
  },
  {
    id: 1,
    src: 'bookmyforex.png',
    width: '307',
    height: '78',
    className: 'max-w-[112px]',
    alt: 'bookmyforex',
  },
  // {
  //   id: 2,
  //   src: 'signzy.png',
  //   width: '309',
  //   height: '90',
  //   className: 'max-w-[102px]',
  //   alt: 'Signzy',
  // },
  {
    id: 3,
    src: 'bajajfinserv.png',
    width: '214',
    height: '57',
    className: 'max-w-[92px]',
    alt: 'Bajaj Finserv',
  },
  {
    id: 4,
    src: 'rapido.png',
    width: '175',
    height: '96',
    className: 'max-w-[64px]',
    alt: 'Rapido',
  },
  {
    id: 5,
    src: 'dunzo-black.png',
    width: '247',
    height: '72',
    className: 'max-w-[82px]',
    alt: 'Dunzo',
  },
];

export const sliderSettings = {
  dots: false,
  arrows: false,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 0,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  slidesToShow: heroLogoData.length,
  variableWidth: true,
  rtl: false,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        rtl: true,
        draggable: false,
        autoPlay: true,
        autoplay: true,
        speed: 2000,
        swipe: false,
        autoplaySpeed: 2000,
        cssEase: 'linear',
        slidesToShow: 2,
        infinite: true,
        centerMode: false,
        variableWidth: true,
      },
    },
  ],
};
