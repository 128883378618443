import React, { useRef } from 'react';
import Code from '../components/Code';
import BAVDropDownPanel from '../components/DropDownPanel/BAVDropDownPanel';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import OtherProducts from '../components/OtherProducts';
import Scrollspy from '../components/Scrollspy';
import SEO from '../components/SEO';
import UseCaseSlider from '../components/Sliders/UseCaseSlider/UseCaseSlider';
import Tabs from '../components/Tabs/Tabs';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import EnterprisePricing from '../components/common/EnterprisePricing/EnterprisePricing';
import StackSlider from '../components/Sliders/StackSlider/StackSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  checkList,
  useCases,
  tryCheckList,
  terminalLine,
  verificationSuiteData,
  otherFeatureData,
  bavWho,
  faqData,
  otherProducts,
  nocode,
  instantBAV,
  integratedBAV,
  heroLogoData,
  sliderSettings,
} from '../content/bank-account-verification';
import '../styles/components/section-developers.scss';
import '../styles/bank-account-verification.scss';

function BankAccountVerification(rest) {
  const container = useRef();

  return (
    <Layout
      {...rest}
      lightHeader
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=BAV%20Page&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Instant Bank Account Verification - Verify Bank Details Online with Cashfree Payments"
        description="Bank account verification -  Use powerful APIs to automate bank account number verification. Works for all bank accounts in India."
        url="https://www.cashfree.com/bank-account-verification/"
        keywords={[
          'Bank Account Verification',
          'Bank Account Check',
          'Check bank account details',
          'Online bank account check',
          'Verify bank account number',
          'Verify bank account',
        ]}
      />
      <section className="section-padding bg-cf-dark bav-hero overflow-hidden relative">
        <div className="container position-relative z-10">
          <div className="flex flex-wrap flex-col lg:flex-row lg:flex-nowrap w-full lg:w-[120%]">
            <div className="w-full lg:w-[40%] pt-0 lg:self-center">
              <h1 className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Bank Account Verification
              </h1>
              <div className="mb-3 md:mb-5 text-xl md:text-2xl font-heading font-semibold max-w-[500px] ">
                Verify bank account details instantly
              </div>
              <div className="text-opacity-80 max-w-[565px] text-[16px] leading-[24px] md:text-2.5md  font-body">
                <h2 className="font-body inline">Verify bank accounts</h2> and
                holder name during onboarding KYC and before making payouts.
                <span className="block mt-4 md:mt-6">
                  Complete account verification suite:
                </span>
              </div>
              <ul className="p-0 mt-3 md:mt-3 mb-4 md:mb-[48px] list-none max-w-full flex flex-wrap flex-row md:items-center justify-start">
                {checkList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] md:mb-0 mr-[24px] lg:mr-4 xl:mr-[24px] last:mr-0 flex "
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white  bg-opacity-25">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <a
                className="button button-green mb-12 lg:mb-0 wide w-full md:w-auto"
                href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_Hero">
                Create Account <span className="chevron" />
              </a>{' '}
            </div>
            <div className="w-full md:w-[115%] lg:w-[60%] lg:pl-[19px] flex justify-center h-full items-center">
              <img
                className="block md:hidden"
                src="/img/bav/hero-mb.png"
                alt="bank account verification"
                width="686"
                height="706"
              />
              <div className="hidden md:block">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/bav/poster.png"
                  width="2076"
                  height="1144"
                  mp4Src="/img/bav/hero-video.mp4"
                  webmSrc="/img/bav/hero-video.webm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="logo-container pt-[56px] md:pt-[86px]">
          <div className="container">
            <div className="relative overflow-hidden  z-[1]">
              <HeroLogoSlider
                data={heroLogoData}
                imgUrl="/img/slider-logo"
                itemClassName="mx-[40px] flex items-center align-center h-[50px]"
                settings={sliderSettings}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative bg-white  bav-use-case overflow-hidden">
        <div className="container relative z-[1]">
          <div className="text-shs md:text-shl font-semibold text-center mb-4 md:mb-6 font-heading">
            Use Cases
          </div>
          <StackSlider
            data={useCases}
            imageClass="object-cover md:object-[initial] aspect-[1.14] md:aspect-auto"
          />
        </div>
        <div className="relative pt-9 md:pt-[67px]">
          <div className="container mt-[48px] md:mt-0">
            <div className="flex flex-wrap md:flex-nowrap flex-col md:flex-row md:max-h-[350px] rounded-[4px] bg-cf-light-grey px-4 md:px-0 overflow-hidden exact-match-bg pb-[32px] md:pb-0">
              <div className="pt-[32px] pb-[40px] md:py-[77px] w-full md:w-[58%] self-center md:pl-28 z-10 relative text-center md:text-left">
                <div className="text-[16px] leading-[24px] md:text-3md font-semibold mb-5 md:max-w-[400px] text-left">
                  Making transactions fool-proof by verifying Account
                  Holder&nbsp;Name
                </div>
                <ul className="p-0 list-none w-full flex flex-col justify-start text-left pr-4 md:pr-0">
                  {tryCheckList.map(({ id, listItem }) => (
                    <li
                      className="mb-[12px] md:mb-[16px] last:mb-0  mr-[24px] lg:mr-4 xl:mr-[24px] last:mr-0 flex justify-start items-center"
                      key={id}>
                      <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-cf-primary  self-start md:self-center">
                        <Icon
                          name="tick"
                          width="9.21px"
                          height="6.29px"
                          fill="#fff"
                        />
                      </span>
                      <h3 className="font-body !m-0 text-2sm md:text-base">
                        {listItem}
                      </h3>
                    </li>
                  ))}
                </ul>
                <a
                  className="button button-green w-full md:w-auto wide mt-8"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_UseCase">
                  Try Now <span className="chevron" />
                </a>
              </div>
              <div className="w-full md:w-auto md:flex-1 md:self-center select-none pointer-events-none z-10">
                <img
                  src="/img/bav/trynow.svg"
                  width="548"
                  height="318"
                  alt=""
                  className="w-full hidden md:block  pr-5"
                />
                <img
                  src="/img/bav/trynowmd.svg"
                  width="317"
                  height="208"
                  alt=""
                  className="w-full block md:hidden"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Scrollspy
        leadContent={
          <div
            className="payout-nav-logo row row-no-margin justify-flex-start"
            style={{ minWidth: '190px' }}>
            <img
              src="/img/icons/bank-account-verification.svg"
              alt="bank-account-verification-icon"
              width="34"
              height="41"
              className="w-[20px]"
            />
            <span>Bank Account Verification</span>
          </div>
        }>
        <section
          data-spy-title="Features"
          data-spy-id="features"
          className="relative p-section-3">
          <div className="container">
            <div className="flex flex-wrap justify-center items-stretch mx-[-15px]">
              <div className="flex-auto w-full md:w-auto md:flex-1 px-[15px]">
                <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] md:hidden">
                  BANK ACCOUNT VERIFICATION
                </p>
                <div className="sticky top-[180px] max-w-[525px] md:mb-[130px]">
                  <h2 className="block font-heading font-semibold text-lg md:text-xl mb-3 md:mb-4">
                    Instant Bank Account Verification
                  </h2>
                  <div className="text-2sm md:text-md max-w-[390px]">
                    Verify the bank account details of users individually or in
                    bulk via APIs and excel upload, and make sure your next
                    payment is going to the right&nbsp;beneficiary.
                  </div>
                  <div className="mt-6 md:mt-8">
                    <a
                      href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_InstantBAV"
                      className="button button-green w-full md:w-auto wide">
                      Create Account <span className="chevron" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex-1 px-[15px] mt-8 mb-2 md:my-0">
                {instantBAV.map(({ id, iconName, title, content }) => (
                  <div
                    key={id}
                    className="mb-8 md:mb-[130px] md:max-w-[500px] flex flex-col md:flex-row items-start md:block">
                    <img
                      className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] mb-3 md:mb-4"
                      src={iconName}
                      alt={title}
                      loading="lazy"
                    />
                    <div>
                      <div className="text-2md md:text-3md font-bold md:font-semibold mb-1 md:mb-2">
                        {title}
                      </div>
                      <div className="text-2sm md:text-md">{content}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="p-section-2 bg-cf-light">
            <div className="container">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                BUILT FOR ENTERPRISES
              </p>
              <div className="text-shs md:text-shl font-semibold text-cf-hero max-w-[610px] mb-7 md:mb-5 font-heading">
                Here is why growing businesses trust Cashfree Bank Verfication
              </div>
              <BAVDropDownPanel />
            </div>
          </div>

          <div className="pt-12 md:pt-32 overflow-hidden">
            <div className="container">
              <div className="flex flex-col items-center">
                <span className="block font-heading text-lg md:text-xl font-semibold mb-8 md:mb-[60px] max-w-[778px] text-center">
                  Who can use Bank Account Verification by Cashfree Payments?
                </span>
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mx-0 md:mx-[-19px]">
                  {bavWho.map(({ id, description, heading, icon }) => (
                    <div
                      key={id}
                      className="w-full px-0 md:px-[19px] mb-4 md:mb-[40px]">
                      <div className="bg-cf-light-grey px-4 py-6 h-full rounded">
                        <div className="w-[32px] md:w-[40px] h-[32px] md:h-[40px] mb-5 md:mb-8">
                          <img
                            className="w-full"
                            width="40"
                            height="40"
                            src={`/img/bav/icons/${icon}`}
                            alt=""
                          />
                        </div>
                        <div>
                          <h3 className="font-body font-bold md:font-semibold mb-2">
                            {heading}
                          </h3>
                          <p className="text-2sm">{description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-4 md:mt-0 w-full text-center">
                  <a
                    className="button button-green w-full md:w-auto wide"
                    href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_WhoCanUseBAV">
                    Create Account <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section-developers cashgram-bg py-12 md:py-24"
          data-spy-title="Integrations"
          data-spy-id="integrations">
          <img
            src="/img/cashgram-bg-2.svg"
            className="parallax-img parallax-img-2 invisible md:visible"
            alt=""
          />
          <img
            src="/img/bav/simple-triangle.svg"
            className="bav-simple-img md:hidden"
            alt=""
          />
          <div className="container position-relative">
            <div className="single-dev-block !pb-2 md:!pb-[116px]">
              <span className="block text-sm font-semibold text-cf-white pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                INTEGRATION
              </span>
              <span className="block font-heading text-lg md:text-xl font-semibold text-cf-white mb-7.5x max-w-[710px]">
                Simplest and easiest to{' '}
                <h2 className="inline">
                  integrate Bank Account Verification API
                </h2>
              </span>
              <div className="text-cf-cold-purple pt-4 max-w-[600px] text-2sm md:text-md">
                Integrate powerful{' '}
                <h2 className="text-white font-body inline">
                  Bank Account Verification API
                </h2>
                s with your product using only a few lines of code and automate
                the onboarding process and ensure successful payouts with
                Cashfree Payments.
              </div>
              <ul className="check-list-round max-w-[620px] text-2sm md:text-md">
                {integratedBAV.map(({ id, item }) => (
                  <li key={id}>
                    <span className="mt-0.5">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <p>{item}</p>
                  </li>
                ))}
              </ul>

              <a
                href="https://docs.cashfree.com/reference/pg-new-apis-endpoint"
                className="button button-green w-full md:w-auto md:min-w-[278px]">
                View API documentation <span className="chevron" />
              </a>

              <div className="terminal-asset mt-[60px]">
                <div className="terminal-tabs before:!w-[calc(100%-68px)] md:before:!w-[calc(100%-150px)]">
                  <span className="!min-w-[68px] md:!min-w-[150px] active">
                    cURL
                  </span>
                  <span className="!min-w-[68px] md:!min-w-[150px] rounded-bl">
                    Java
                  </span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">
                    Python
                  </span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">PHP</span>
                  <span className="!min-w-[68px] md:!min-w-[150px] ">
                    Node JS
                  </span>
                </div>

                <div className="terminal-body !py-4 !px-6 md:!py-6">
                  <div className="terminal-content">
                    <p className={terminalLine} data-line-number="1">
                      <Code
                        noScroll
                        language="language-javascript"
                        code={`
                        curl 
                        --request GET
                        --url https://payout-api.cashfree.com/payout/v1.2/validation/bankDetails
                        --header 'accept: application/json'                        
                        `}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-dev-block">
              <div className="row  row-no-margin justify-flex-start mobile-wrap">
                <img
                  className="order-1 md:order-none w-full"
                  src="/img/bav/integration.svg"
                  alt=""
                  style={{ maxWidth: 357 }}
                />

                <div className="no-code-solution">
                  <h4 className="body-font white">No code solutions</h4>
                  <ul className="check-list-round my-0 md:mt-5 ">
                    {nocode.map(({ id, item }) => (
                      <li key={id}>
                        <span className="mt-0.5">
                          <Icon
                            name="tick"
                            width="9.21px"
                            height="6.29px"
                            fill="#fff"
                          />
                        </span>
                        <p className="text-2sm md:text-md">{item}</p>
                      </li>
                    ))}
                  </ul>
                  <a
                    href="https://docs.cashfree.com/reference/pg-new-apis-endpoint"
                    className="button button-green w-auto md:min-w-[278px] mt-10 mb-10 md:mb-0">
                    View Documentation <span className="chevron" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="p-section-2 overflow-hidden bg-cf-light-grey">
          <div className="container">
            <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
              Use cases for various industries
            </p>
            <span className="block font-heading text-lg md:text-xl font-semibold text-cf-hero mb-3 md:mb-5 max-w-[610px]">
              Bank Account Verification for innovative&nbsp;businesses
            </span>
            <div className="text-2sm md:text-md max-w-[650px] mb-[32px] md:mb-16 text-cf-hero">
              <h2 className="inline font-body">Verify bank account details</h2>{' '}
              before onboarding and validate accounts before making payouts with
              Cashfree Payments.
            </div>
            <div className="md:mb-[48px]">
              <UseCaseSlider />
            </div>
          </div>
        </section>
        <div className="p-section-3 bav-verification">
          <div className="container">
            <div className="text-shs md:text-shl font-semibold max-w-[495px]  mb-6 md:mb-8 font-heading">
              Complete Verification Suite
            </div>
            <Tabs data={verificationSuiteData} dropDown timerAutoPlay />
          </div>
        </div>
        <section
          data-spy-title="Pricing"
          data-spy-id="Pricing"
          className="p-section-2 bg-cf-light-grey">
          <div className="container bav-pricing-section">
            <div className="flex flex-wrap items-center justify-between mx-[-15px]">
              <div className="w-full p-[15px] xl:w-5/12">
                <p className="mx-auto md:mx-0 w-max md:max-w-max text-tiny font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                  PRICING
                </p>
                <div className="text-cf-hero text-4md leading-8  md:text-xl max-w-[400px] font-semibold mb-[2px] md:mb-10 font-heading text-center md:text-left mx-auto md:mx-0">
                  Flexible pricing plan, to suit your needs
                </div>
                {/* <CheckList data={pricingData} className="mb-0 md:mb-[32px]" /> */}
              </div>
              <div className="w-full p-[15px] xl:w-7/12">
                <div className="flex flex-col justify-center md:flex-row">
                  <div className="w-full xl:max-w-[334px] md:self-center">
                    <div className="p-4 md:p-6 starts-card">
                      <div className="text-vtiny leading-[16px] md:text-2sm text-cf-hero opacity-50 md:font-medium">
                        Starts at
                      </div>
                      <div className="flex md:justify-center items-center mb-[26px] md:mb-6">
                        <div>
                          <div className="text-cf-primary text-[24px] leading-[33px] md:text-2xl relative font-bold">
                            ₹2.50{' '}
                            <span className="inline-block  text-[30px] leading-none text-cf-primary font-light mx-[24px] md:hidden">
                              +
                            </span>
                          </div>
                          <div className="text-2sm">per verification</div>
                        </div>
                        <div className="text-2xl text-cf-primary font-light mb-4 mx-5 hidden md:block">
                          +
                        </div>
                        <div>
                          <div className="text-cf-primary text-[24px] leading-[33px] md:text-2xl relative font-bold">
                            ₹0.50
                          </div>
                          <div className="text-2sm w-[102%]">
                            per name match
                          </div>
                        </div>
                      </div>

                      <div className="font-semibold text-2md text-cf-hero mb-  line pt-[20px] md:pt-6">
                        Verification Suite
                      </div>
                      <div className="text-2sm pt-2 pb-7 md:pb-8">
                        Verify bank account details, UPI IDs or branch IFSC in
                        real-time
                      </div>
                      <a
                        className="button button-green w-full"
                        href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_Pricing">
                        Create Account <span className="chevron" />
                      </a>
                      <small className="italic text-tiny pt-3  pr-1 font-normal  block md:hidden">
                        * GST applicable
                      </small>
                    </div>
                    <small className="italic text-tiny pt-2  pr-1 font-normal  hidden absolute md:block">
                      * GST applicable
                    </small>
                  </div>

                  <EnterprisePricing link="https://www.cashfree.com/contact-sales?source-action=BAV%20Page&action=Contact%20Sales&button-id=ContactSales_Pricing" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Scrollspy>
      <section className="p-section-2 bg-cf-dark text-white other-features-section bav overflow-hidden">
        <div className="container relative z-10">
          <p className="text-sm font-semibold pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] mx-auto inline-block">
            API Integration
          </p>
          <div className="text-shs md:text-shl font-semibold max-w-[710px] mb-[32px] md:mb-16 font-heading">
            Connect Verification APIs with other Cashfree Payments products
          </div>

          <div className="flex flex-wrap flex-row -mx-3 -mt-3 md:mx-[-19px] md:mt-[-19px]">
            {otherFeatureData.map((f) => (
              <div
                className="w-full lg:w-1/3 self-stretch p-3 md:p-[19px] text-2.5sm md:text-md"
                key={f.id}>
                <div className="md:hover:scale-105 transition-all duration-300  bg-white bg-opacity-[0.07] h-[267px] md:h-full px-3 md:px-6 py-3 md:py-8 rounded border border-opacity-10 border-white flex flex-col justify-between">
                  <div>
                    <div className="w-[32px] h-[32px] md:w-[40px] md:h-[40px] flex justify-center items-center">
                      <img
                        src={f.icon}
                        alt={f.title}
                        loading="lazy"
                        style={{ maxWidth: f.maxWidth }}
                        className="w-full  md:!max-w-full"
                      />
                    </div>
                    <div className="font-semibold pt-4 pb-3 text-[16px] text-2md">
                      {f.title}
                    </div>
                    <p className="text-cf-cold-purple text-2sm pb-8">
                      {f.text}
                    </p>
                  </div>
                  <a
                    href={f.url}
                    className="button button-unstyled btn p-0 !text-cf-green group left-[83px] !right-auto">
                    Learn more
                    <svg
                      className="!w-[6px] !h-[14px] ml-2.5 group-hover:translate-x-[3px] transition-transform duration-300"
                      width="6"
                      height="11"
                      viewBox="0 0 6 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M1 1L5 5.5L1 10"
                        stroke="#05C16E"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container general-faqs">
        <FAQ
          open="0"
          supportLinkHref="https://www.cashfree.com/help/hc"
          faqTabs={faqData.tabs}
          data={faqData.data}
          customerData={faqData.customer}
        />
      </section>
      <OtherProducts data={otherProducts} />
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=BAV%20Page&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}
export default BankAccountVerification;
