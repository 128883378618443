import React, { useCallback, useState } from 'react';
import FAQ from '../FAQ';
// import FAQ from '../FAQ';
import Tabs from '../Tabs/Tabs';
import { faqBAVData, tabData } from '../../content/bank-account-verification';

function DropDownPanel() {
  const [accordionAnimImage, setAccordionAnimImage] = useState(0);
  const getAccordionAnimImage = useCallback((id) => {
    setAccordionAnimImage(id);
  }, []);
  return (
    <div className="flex flex-nowrap md:flex-wrap justify-start mx-[-15px]  mt-3 md:mt-[60px]">
      <div className="flex-auto pl-[12px] pr-[23px] w-full md:w-6/12 hidden md:block">
        <div className="relative ">
          <div
            className={`hidden static transform-none mx-auto transition-all duration-300 ease${
              accordionAnimImage === 0 ? ' !block' : ''
            }`}>
            <img
              src="/img/bav/panel-1.svg"
              alt=""
              width="454"
              height="428"
              className="mx-auto"
            />
          </div>
          <div
            className={`hidden static transform-none mx-auto transition-all duration-300 ease${
              accordionAnimImage === 1 ? ' !block' : ''
            }`}>
            <img
              src="/img/bav/panel-2.svg"
              alt=""
              width="506"
              height="369"
              className="mx-auto"
            />
          </div>
          <div
            className={`hidden static transform-none mx-auto transition-all duration-300 ease${
              accordionAnimImage === 2 ? ' !block' : ''
            }`}>
            <img
              src="/img/bav/panel-3.svg"
              alt=""
              width="534"
              height="424"
              className="mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="flex-auto px-[15px] w-full md:w-6/12 bav-faq self-center">
        <div className="hidden md:block">
          <FAQ
            supportLink={false}
            arrow="arrow"
            getActive={getAccordionAnimImage}
            isPayouts
            alwaysOpen
            dataHeading="Providing educational services online or offline?"
            data={faqBAVData}
            style={{
              border: 'none',
              margin: '0',
              padding: '0',
            }}
          />
        </div>
        <div className="block md:hidden bav-tab-panel">
          <Tabs
            onChange={getAccordionAnimImage}
            data={tabData}
            dropDown
            dropDownFontSize="text-[16px]"
            timerAutoPlay
          />
        </div>
        <a
          className="button button-green wide w-full md:w-auto mt-[30px] md:mt-[40px]"
          href="https://merchant.cashfree.com/merchants/signup?source-action=BAV%20Page&action=Sign%20Up&button-id=StartNow_BuiltForEnterprises">
          Create Account <span className="chevron" />
        </a>
      </div>
    </div>
  );
}

export default DropDownPanel;
